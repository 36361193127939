:root {
  --title: #eed;
  --highlight: #eed;
  --normal: #ccb;
  --subtle: #878787;
  --bodyBackground: #282c34;
  --bodyLight: #333842;
  --dark: #1E1E1E;
  --submit: #8EFF78;
  --error: #FF9878;

  --money-low: #8EFF78;
  --money-mid: #FFE778;
  --money-high: #FF9878;
  --money-foreign: #A878FF;

  --bandcamp: #78FBFF;

  --form-focus: #78fcfc;
}

@font-face {
  font-family: 'consolas';
  src: url('fonts/Consolas.ttf');
  font-style: normal;
  font-weight: 900;
}

a {
  color: var(--normal);
}

a:hover {
  color: var(--highlight);
}

.app {
  font-family: consolas;
  background-color: var(--bodyBackground);
}


.app-nav-bar {
  z-index: 10;
  position: fixed;
  text-align: center;
  height: 30px;
  width: 100%;
  padding: 10px 0px;
  /*font-size: calc(10px + 2vmin);*/
  background-color: var(--dark);
}

.app-title-text {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  top: 2px;
  text-decoration: none;
  color: var(--title);
}

.app-nav-logo {
  -webkit-animation: spider-descend 2.5s ease; /* Safari */
  animation: spider-descend 2.5s ease;
}

.app-burger {
  padding: 10px;
  position: fixed;
  top: 0;
  right: 3px;
  font-size: 34px;
  transition: transform 0.2s;
  user-select: none;
  color: var(--title)
}

.app-burger:hover {
  cursor: pointer;
}

.rotate-reset {
 transform: rotate(0deg);
}

.rotate-clockwise {
 transform: rotate(-90deg);
}

.app-router {
  display: grid;
  text-align: end;
  background-color: var(--dark);
  z-index: 2;
  width: 100%;
  position: fixed;
  transition: top 0.2s;
  top: 47px;
  box-shadow: 0 4px 12px 0px var(--dark);
}

.app-router-up {
  /*top: -64px;*/
  top: -110px;
}

.app-link {
  color: var(--normal);
  margin: 6px;
  text-decoration-line: none;
  border-bottom: dashed;
  border-width: thin;
  padding-bottom: 5px;
  user-select: none;
  font-size: 19px;
  font-style: italic;
  padding: 0px 10px 5px 0px;
}

.app-link:hover {
  color: var(--highlight);
}

.app-footer {
  padding: 30px;
  text-align: center;
  color: var(--subtle);
  background-color: var(--dark);
}

.app-footer-img {
  width: 50px;
  padding-top: 15px;
}

.app-body-wrapper {
  width: 100%;
  text-align: center;
      float: left;
    position: relative;
}

.app-body {
  display: inline-block;
  color: var(--normal);
  padding: 10px 25px;
  text-align: left;
  max-width: 500px;

}

.app-center {
  padding-top: 10px;
  text-align: center;
}

/*home*/

.home {
  text-align: center;
}

.home-logo {
  pointer-events: none;
  padding: 20px;
  user-select: none;
  color: var(--title);
}

.home-header {
  padding: 20px 5px;
  background-color: var(--bodyBackground);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(14px + 2vmin);
  color: var(--highlight);
}

.home-sub-header {
  color: var(--normal);
  text-align: center;
  padding: 0px 10px;
}

.page {
  padding-top: 47px;
  min-height: calc(100vh - 197px);
}


/*merch*/

.merch-feed {
  padding-top: 20px;
  text-align: center;
}

.merch-feed-grid-wrapper {
  display: flex;
  justify-content: center;
}

.merch-feed-grid {
  display: grid;
  grid-template-columns: auto;
}

.merch-wrapper {
  padding: 5px 5px 30px 5px;
  text-align: center;
  color: var(--normal);
  width: 300px;
}

.merch-img-wrapper {

}

.merch-img {
  width: 300px;
  box-shadow: 0 4px 12px 0px var(--dark);
}

.merch-title {
  padding-bottom: 5px;
}

.merch-sorter {
  text-align: left; 
  border-bottom: dashed;
  border-width: thin;
  color: var(--normal);
  width: 300px;
  display: inline-block;
  margin: 20px 5px; 
  padding-bottom: 2px; 
}

.merch-sorter-button {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin: 1px;
  padding: 3px;
}

.merch-sorter-button:hover {
  margin: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: .25rem;
  border-color: var(--subtle);
  background-color: var(--dark);
}

.merch-error {
  color: var(--error);
  padding: 20px 5px;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .home-logo {
    animation: home-logo-spin infinite 6s linear;
    font-size: 80px;
  }
}

@keyframes home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*find*/
.find-spacer {
  padding: 40px 5px 10px;
  color: var(--normal);
}

.find-bc {
  padding-top: 40px;
  color: var(--normal);
}

.typeahead-wrapper {
  text-align: center;
  padding: 20px 0px;
}

.typeahead-row {
  text-align: left;
  margin: auto;
  width: 315px;
  padding: 6px 5px 0px;
  margin-bottom: 5px;
  border-radius: 0.25rem;
}

.typeahead-row:hover {
  cursor: pointer;
  background-color: var(--bodyLight);
}

.typeahead-row:active {
  background-color: var(--bodyLight);
}

.typeahead-col {
  display: inline-block;
}

.typeahead-img {
  min-width: 60px;
  min-height: 60px;
  height: 60px;
  border-radius: 50%;
}

.typeahead-name {
  padding-left: 10px;
  position: relative;
  bottom: 29px;
  color: var(--title);
}

.typeahead-username {
  padding-left: 10px;
  position:  relative;
  bottom: 25px;
  color: var(--normal);
}

.typeahead-loading-style {
  color: var(--subtle);
}

.typeahead-img-loading {
  filter: grayscale(100%);
}

/*login*/
.login-form {
  justify-content: center;
  display: grid;
}

.login-button {
  color: var(--dark) !important;
  background-color: var(--form-focus) !important;
  font-family: 'consolas' !important;
}

/*input*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px var(--dark) inset !important;
}


/*Change text in autofill textbox*/
input:-webkit-autofill
{
 -webkit-text-fill-color: var(--highlight) !important;
}
input         {
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:300px;
  border:none;
  border-bottom:1px solid #757575;
  background: rgba(0,0,0,0);
  color: var(--highlight)
}

input:focus     { outline:none; }

label          {
  color:var(--normal); 
  font-size:18px;
  font-weight:normal;
  position: relative;
  pointer-events: none;
  left: 5px;
  top: -32px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

input:focus ~ label, input:valid ~ label    {
  top:-61px;
  font-size:14px;
  color:var(--form-focus);
}

.bar  { position:relative; display:block; width:300px; }
.bar:before, .bar:after   {
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:var(--form-focus); 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}

input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

.highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

.group {
  height: 65px;
  position:relative; 
}

input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect:focus {
    outline: none !important;
}


/*loader*/
.loader-wrapper {
  text-align: center;
}

.loader {
  border-radius: 50%;
  -webkit-animation: loader-spin 1s linear infinite; /* Safari */
  animation: loader-spin 1s linear infinite;
  display: inline-block;
}


@-webkit-keyframes loader-spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes loader-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spider-descend {
  0% { transform:   translateY(-45px); }
  20% { transform:  translateY(-20px); }
  40% { transform:  translateY(-18px); }
  60% { transform:  translateY(-11px); }
  80% { transform:  translateY(-10px); }
  100% { transform: translateY(0px); }
}


/*button*/
button {
  font-family: consolas;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: background 400ms;
  padding: 0.7rem 1rem;
  font-size: 1.2rem;
  outline: 0;
  background-color: var(--bodyBackground);
  border-width: 2px;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3); /* black with 30% opacity */
  cursor: pointer;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear !important;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


/*resize media*/

@media screen and (min-width: 800px) { /*2 wide*/
  /*merch*/
  .merch-feed-grid {
    grid-template-columns: auto auto;
  }
  .merch-sorter {
    width: 695px;
  }
  .merch-wrapper {
    padding: 5px 47px 30px 47px;
  }
  .merch-img-wrapper {
    min-height: 300px;
  }

  /*about*/
  .about-spinning-spider {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) { /*3 wide*/
  /*merch*/
  .merch-feed-grid {
    grid-template-columns: auto auto auto;
  }
  .merch-sorter {
    width: 1090px;
  }
}




/*custom animation*/
.fade-in {
    -webkit-animation: 1s ease 0s normal forwards 1 fadein;
    animation: 1s ease 0s normal forwards 1 fadein;
}

@keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}

@-webkit-keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}


.merch-debug {
  z-index: 2000;
  position: fixed;
  bottom: 10px;
  background-color: black;
  color: red;
  padding: 10px;
}